// set basepath of project for webpack
import { basePath } from './core/config';
__webpack_public_path__ = `${basePath}/assets/frontend/js/`;

import 'console-polyfill';

import $ from 'jquery';

// jquery utils
import 'jquery.easing';

import './plugins/nette.ajax';
import './plugins/giant.trigger';
import './plugins/giant.autosubmit';
import './plugins/giant.cloner'

// nette
import './nette/NetteValidationMessages';
import './nette/NetteSnippets';

import Nette from './nette/NetteForms';
window.Nette = Nette;
Nette.initOnLoad();

if ($.nette) {
    $.nette.init();
}

import components from './components/';
import { loadComponents } from './core/componentUtils';

window.app = {
    components: components
};

loadComponents();