// general
import Antispam from '../../components/Antispam'
// import CookieBubble from '../../components/CookieBubble'

// project
import DateInput from './DateInput'
import FixedBlock from './FixedBlock'
import FormStatement from './FormStatement'
import Modal from './Modal'
import ModalOpener from './ModalOpener'
import SmoothScroll from "./SmoothScroll";
import Select from "./Select";

const Components = {
    Antispam,
    // CookieBubble,
    DateInput,
    FixedBlock,
    FormStatement,
    ModalOpener,
    Modal,
    Select,
    SmoothScroll
}

export default Components