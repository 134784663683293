import Component from '../core/Component';

export default class ModalOpener extends Component {

    prepare() {
        this.element.addEventListener('click', :: this.handleClick);
    }

    handleClick(event) {
        event.preventDefault()

        let id = this.element.getAttribute('href').split("#")[1]

        if (!id) {
            return;
        }

        const target = document.getElementById(id);

        if (!target) {
            return;
        }

        const modal = Component.getFromElement(target);

        if (modal) {
            modal.open();
        }

    }

}