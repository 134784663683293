import { query, queryAll } from '../utils/dom'
import  { componentKey } from './config'

export default class Component {

    constructor(element) {
        this.element = element
        this.element[componentKey] = this
        this._ref = {}
    }

    get ref() {
        return this._ref
    }

    set ref(items = {}) {
        const allRefs = queryAll('[data-ref]', this.element)

        this._ref = Object.keys(items)
            .map(key => {
                const isArray = Array.isArray(items[key])

                // non-empty refs
                if (items[key] !== null && (isArray && items[key].length > 0)) {
                    return {
                        name: key,
                        value: items[key]
                    }
                }

                const name = isArray ? key.slice(0, -1) : key
                const prefixedName = `${this._name}:${name}`

                let refs = allRefs.filter(element => element.getAttribute("data-ref") === prefixedName)

                if (refs.length === 0) {
                    refs = allRefs.filter(element => element.getAttribute("data-ref") === name)
                }

                if (!isArray) {
                    refs = refs.length ? refs[0] : null
                }

                return {
                    name: key,
                    value: refs
                }
            })
            .reduce((acc, ref) => {
                acc[ref.name] = ref.value
                return acc
            }, {})

        return this._ref
    }

    async require() {
    }

    _load() {
        this.require().then(::this.prepare)
    }

    prepare() {
        console.warn(`Component ${this._name} does not have "prepare" method.`)
    }

    destroy() {
        //console.warn('Destroy method: override me')
    }

    is(state) {
        return this.element.classList.contains(state)
    }

    getElement() {
        return this.element
    }

    getRef(ref, prefixed = false) {
        return `[data-ref="${prefixed ? `${this._name}:` : ''}${ref}"]`
    }

    static getFromElement(element) {
        return element[componentKey]
    }

}