import Component from '../core/Component'
import { enableScrolling, disableScrolling } from "../services/Viewport/scroll";

export default class Modal extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            closes: []
        }

    }

    prepare() {
        this.ref.closes.forEach(close => {
            close.addEventListener('click',::this.close)
        });
    }

    close(e) {
        e.preventDefault();
        
        enableScrolling();
        this.element.classList.remove('is-active')
    }

    open() {
        disableScrolling();
        this.element.classList.add('is-active')
    }
}