import Component from '../core/Component';
import Nette from '../nette/NetteForms'

export default class FormStatement extends Component {
    prepare() {
        $(this.element).on("cloneradd",::this.handleClonerAdd)
    }

    handleClonerAdd(event,item) {
        Nette.initForm(item)
    }
}