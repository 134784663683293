import Component from '../core/Component'
import Cleave from 'cleave.js';

export default class DateInput extends Component {

    prepare() {
        var cleave = new Cleave(this.element, {
            date: true,
            delimiter: '.',
            datePattern: ['d', 'm', 'Y']
        });
    }

}